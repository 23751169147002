/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.p-treetable-thead th {
  text-align: left;
}

/* New styles for adding borders */
/* .p-treetable {
  border: 1px solid #dee2e6;
} */

.p-treetable-thead th,
.p-treetable-tbody td {
  /* border-bottom: 1px solid #dee2e6; */
  padding: 4px 0;
  font-size: 14px;
  font-weight: 600;
}

.p-treetable-thead th:last-child,
.p-treetable-tbody td:last-child {
  /* border-right-width: 1px; */
}

.p-treetable-thead th {
  min-width: 100px;
  text-align: center;
}

table>tbody.p-treetable-tbody>tr>td {
  min-width: 100px;
  text-align: center;
}

table>tbody.p-treetable-tbody>tr>td:first-child {
  /* width: 200px !important; */
  text-align: left;
}

/****18 september global css for padding i have added here because nothing is working for removing right padding ***/
.p-treetable-scrollable-header-box{
  padding-right: 0 !important;
}

/* Increase width of first column and ensure text stays on a single line */
.tree-table table > thead > tr > th:first-child,
.tree-table table > tbody > tr > td:first-child {
  min-width: 150px !important;
    padding-right: 100px !important;
    white-space: nowrap; /* Prevent text wrapping */
    
}
span.mat-mdc-option .mdc-list-item__primary-text{
margin-right: 200px !important;
}
div.mat-mdc-select-panel .mat-mdc-option{
  margin-left: -7px !important;
}
/****Global css for shadow *******/
.shadow-class{
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}
/**** code for the team-tracker table ***** 22 september ****/
.mat-sort-header-content{
    margin-left: 27px !important;
}


/********23 B - 2024***** fonts for all dashboard with  ***/
.global-heading{
  font-size:1.2rem !important;
  font-weight: 500 !important;
  color: #000000 !important;
}

.global-fonts-style{
  font-size: 1.05rem !important;
  font-weight: 400 !important;
  color: #000000 !important;
}
.global-table-body-fonts{
  font-size: .9rem !important;
  font-weight: 400px !important;
  color: #000000 !important;
}
.mat-mdc-select-value-text {
  font-size: 13px;
}
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input{
  font-size: 13px;
}